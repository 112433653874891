import { getTicketDuration } from './ticket-duration';
interface Ticket {
    canActivateFrom: string;
    canActivateTo: string;
    changeAllowed: string;
    config: any;
    customDurationRange: CustomDurationRange | null;
    customDurationRangeId: string;
    displayedFrom: string;
    displayedTo: string;
    duration: string;
    validFrom: string;
    validTo: string;
    id: string;
    inSaleFrom: string;
    inSaleTo: string;
    isBearer: string;
    metadata: Metadata;
    name: string;
    priceWithTax: string;
    priceWithoutTax: string;
    referenceNumber: string;
    transportAuthorityId: string;
    tags: Tag[];
    transportLines: TransportLine[];
    transportZones: TransportZone[];
    createdAt: string;
    updatedAt: string;
}

interface Tag {
    id: string;
    name: string;
    tagGroupId: string;
    transportAuthorityId: string;
    createdAt: string;
    updatedAt: string;
}

interface TransportLine {
    id: string;
    name: string;
    transportAuthorityId: string;
    createdAt: string;
    updatedAt: string;
}

interface TransportZone {
    id: string;
    name: string;
    transportAuthorityId: string;
    referenceNumber: string;
}

interface CustomDurationRange {
    count: string;
    durationDescription: string;
    fromDate: string[];
    fromDay: string[];
    id: string;
    name: string;
    toDate: string[];
    toDay: string[];
    transportAuthorityId: string;
    type: string;
    createdAt: string;
    updatedAt: string;
}

interface Metadata {
    durationDescription?: string;
    detailedDescription?: string;
    zoneDescription?: string;
    priceDescription?: string;
}

const millisecondsMap = [
    {
        type: `seconds`,
        value: 1000,
    },
    {
        type: `minutes`,
        value: 60000,
    },
    {
        type: `hours`,
        value: 3600000,
    },
    {
        type: `days`,
        value: 86400000,
    },
    {
        type: `years`,
        value: 31557600000,
    },
];

export const calculateViewTicketZone = (ticket: Ticket) => {
    let zone = ``;

    if (ticket.metadata.zoneDescription) {
        return ticket.metadata.zoneDescription;
    }

    if (ticket.transportZones.length > 0) {
        zone = ticket.transportZones
            .map((el) => {
                return el.name;
            })
            .join(`, `);
    }

    return zone;
};

export const calculateViewTicketDiscount = (ticket: Ticket) => {
    let discount = ``;
    if (ticket.tags.length > 1) {
        ticket.tags.forEach(element => {
                if (element.tagGroupId === `e1631456-6f31-42b8-b7e8-03686b3bc208`) {
                    discount = element.name
                }
            }
        )
    } else {
        discount = `normalny`
    }
    return discount;
}

export const calculateTicketDiscountId = (ticket: Ticket) =>{
    let discountId = ``;
    if (ticket.tags.length > 1) {
        ticket.tags.forEach(element => {
                if (element.tagGroupId === `e1631456-6f31-42b8-b7e8-03686b3bc208`) {
                    discountId = element.id
                }
            }
        )
    }
    return discountId;
}

export const calculateTicketZoneAndArea = (ticket: Ticket) =>{
    let zoneAndArea = null;
    if (ticket.tags && ticket.tags.length > 0 ) {
        ticket.tags.forEach(function (tag: any) {
            if (tag.tagGroupId === "5a3bc3cc-e6b2-4379-8074-208a00c7c8d4"){
                zoneAndArea = { zone: ticket.transportZones[0].name, area: tag.name}
            }
        })
    }
    return zoneAndArea;
}

export const calculateViewTicketDuration = (ticket: Ticket) => {
    if (ticket.duration && ticket.duration.length > 0) {
        const splitDuration = ticket.duration.split(` `);
        const msObj = millisecondsMap.find((el) => {
            return el.type === splitDuration[1];
        });

        if (splitDuration[1] === `months`) {
            return `${splitDuration[0]} msc`;
        }

        if (!msObj) {
            return ``;
        }

        return getTicketDuration(msObj.value * Number(splitDuration[0]));
    }

    if (ticket.customDurationRange) {
        return ticket.customDurationRange.name;
    }
};
